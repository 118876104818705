<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="items.length > 0">
      <v-row dense>
        <v-col>
          <h2>
            {{ formatNumber(matchbackCustomers.length) }} Matchbacks
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col
        cols="12"
        sm="12"
        lg="6"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-car"
          title="Vehicle Sales"
          :value=salesPanelMessage()
          sub-icon="mdi-tag"
          sub-text="Tracked from your DMS"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        lg="6"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-wrench"
          title="Vehicle Sales"
          :value=servicePanelMessage()
          sub-icon="mdi-tag"
          sub-text="Tracked from your DMS"
        />
      </v-col>      
    </v-row>

    <template>
      <v-container fluid>
        <v-radio-group v-model="selectedCustomerType">
          <template v-slot:label>
            <div>Which types of matchbacks do you want to see?</strong></div>
          </template>
          <v-radio value="Sales">
            <template v-slot:label>
              <div>I just want to see <strong class="success--text">Sales</strong> matchbacks</div>
            </template>
          </v-radio>
          <v-radio value="Service">
            <template v-slot:label>
              <div>I just want to see <strong class="success--text">Service</strong> matchbacks</div>
            </template>
          </v-radio>
          <v-radio value="All">
            <template v-slot:label>
              <div>I want to see both <strong class="success--text">Service</strong> and <strong class="success--text">Service</strong> matchbacks</div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>
    </template>
    
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="matchbackCustomers"
            :search="search"
            :items-per-page="50"
            :sort-by="['name']"
          >
            <template #item.reference_date="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template #item.phone="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
            <template v-slot:item.first_name="{ item }">
              <router-link
                style="cursor: pointer; color: blue; text-decoration: underline"
                :to="{ name: 'Customer', params: { campaign_id: campaignId, customer_id: item.chatovate_customer_uuid } }"
              >
                {{ item.first_name }} {{ item.last_name }}
              </router-link>
            </template>   
            <template #item.sale_amount="{value}">
              {{ formatPrice(value) }}
            </template>                       
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'ChatovateMatchbackResults',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        campaignId: null,
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        campaignType: '',
        numberSales: 0,
        numberROs: 0,
        salesAmount: 0,
        serviceAmount: 0,
        selectedCustomerType: 'Sales',
        headers: [
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Deal # / RO #', value: 'reference_number', align: 'center' },
          { text: 'Deal Date / RO Date', value: 'reference_date', align: 'center' },
          { text: 'Customer Type', value: 'customer_type', align: 'center' },
          { text: 'Customer #', value: 'customer_number', align: 'center' },
          { text: 'Sale Amount', value: 'sale_amount', align: 'center' },          
        ],
      }
    },
    computed: {
      matchbackCustomers() {
        if (this.selectedCustomerType === 'Sales') {
          return this.items.filter(i => i.customer_type === 'Sales')
        }
        if (this.selectedCustomerType === 'Service') {
          return this.items.filter(i => i.customer_type === 'Service')
        }
        return this.items

      },
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadMatchbacks()
      },
      watch: {
      },
    },
    created () {
      this.selectedCustomerType = this.$route.query.customer_type
      this.loadMatchbacks()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadMatchbacks: function () {
        this.campaignId = (this.$route.params.campaign_id)
        this.items = []
        this.loading = true
        ChatovateService.getMatchbackResults(this.campaignId)
          .then(response => {
            this.items = response.data
            this.numberSales = this.items.filter(i => i.customer_type === 'Sales').length
            this.salesAmount = this.items.filter(i => i.customer_type === 'Sales').map(i => i.sale_amount).reduce((a, b) => a + b, 0)
            this.numberROs = this.items.filter(i => i.customer_type === 'Service').length
            this.serviceAmount = this.items.filter(i => i.customer_type === 'Service').map(i => i.sale_amount).reduce((a, b) => a + b, 0)
            
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      salesPanelMessage: function () {
        return this.numberSales + " sold vehicles with total gross of " + this.formatPrice(this.salesAmount)
      },
      servicePanelMessage: function () {
        return this.numberROs + " closed RO's with total revenue of " + this.formatPrice(this.serviceAmount)
      },
      
    },
  }
</script>
